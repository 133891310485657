import { CarePlanStatus } from "../../carePlanStatus/CarePlanStatus";
import { PublishModalProps } from "../../../lib/interfaces/carePlan";
import styles from "./style.module.css";
import {
  PillarInfoContainer,
  PillarInfoHeader,
  NoCarePlan,
  DropdownMenu,
} from "./Styled";
import {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { BaseContext, AlertContext } from "../../../lib/context/context";
import clsx from "clsx";
import { Article, DotsThree } from "phosphor-react";
import { useCarePlan } from "../../../lib/hooks/useCarePlan";
import { useParams } from "react-router-dom";
import { Spinner } from "../../spinner/Spinner";
import { Button } from "../../button/Button";
import PublishModal from "../Modal/PublishModal";
import { PatientDetailProps } from "../../../lib/interfaces/carePlan";
import { formatLastUpdatedDate } from "../../../lib/util/date";

const columns = [
  { text: "Template Name", style: styles.column1 },
  { text: "Last Saved", style: styles.column2 },
  { text: "Last Updated By", style: styles.column3 },
  { text: "Status", style: styles.column4 },
  { text: "Last Published", style: styles.column5 },
  { text: "", style: styles.column6 },
];

interface PatientRouteParam {
  userId: string;
}

interface ProviderInfo {
  name: string;
  photoUrl: string;
}

export enum PublishModalTypes {
  Draft = "Draft",
  Published = "Published",
  Unpublish = "Unpublish",
  Changed = "Changed",
}

export interface publishModalProps {
  isVisible: boolean;
  modalType?: PublishModalTypes;
}

const initalModalValues: publishModalProps = {
  isVisible: false,
};

export const CarePlanPillarDetail = ({
  pillarDetail,
  patient,
}: {
  pillarDetail: Omit<PublishModalProps, "patient" | "careplanId">;
  patient: PatientDetailProps;
}) => {
  const {
    allProviders,
    selectedPillarData,
    setSelectedTemplateData,
    refetchPillarTemplate,
    selectedTemplateData,
  } = useContext(BaseContext);
  const { clearAlerts } = useContext(AlertContext);
  const { userId } = useParams<PatientRouteParam>();

  const [publishModal, setPublishModal] =
    useState<publishModalProps>(initalModalValues);

  const { usePillarTemplates } = useCarePlan();

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;

  const {
    data: pillarTemplateData,
    isLoading,
    isFetching,
    refetch,
  } = usePillarTemplates(
    userId,
    selectedPillarData?.pillarId || "",
    currentPage + 1,
    pageSize
  );

  const pageCount = useMemo(() => {
    if (pillarTemplateData && pillarTemplateData?.contents?.length > 0) {
      return Math.ceil(pillarTemplateData?.totalCount / pageSize);
    }
    return 0;
  }, [pillarTemplateData, pageSize]);

  const changePage = useCallback(
    (page: number) => {
      if (page <= 0) {
        page = 0;
      } else if (page > pageCount - 1) {
        page = pageCount - 1;
      }
      setCurrentPage(page);
    },
    [pageCount]
  );

  useEffect(() => {
    if (currentPage >= 0) {
      refetch();
      clearAlerts();
    }
  }, [currentPage]);

  useEffect(() => {
    if (refetchPillarTemplate) {
      refetch();
      clearAlerts();
    }
  }, [refetchPillarTemplate]);

  const [openMenuId, setOpenMenuId] = useState<any>(null);
  const menuRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openMenuId) {
        const menu = menuRefs.current[openMenuId];
        if (menu && !menu.contains(event.target as Node)) {
          setOpenMenuId(null);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenuId]);

  const handleDotsClick = (id: string) => {
    setOpenMenuId((prevId: string) => (prevId === id ? null : id));
  };

  function getProviderInfoById(providerId: string): ProviderInfo | null {
    if (!Array.isArray(allProviders)) {
      return null;
    }

    const provider = allProviders.find(
      (provider) => provider.providerId === providerId
    );

    if (provider) {
      return {
        name: provider?.name || "",
        photoUrl: provider?.photoUrl || "",
      };
    }
    return null;
  }

  const handleCloseModal = () => {
    setPublishModal(initalModalValues);
  };

  const handleOpenModal = (type: PublishModalTypes) => {
    setPublishModal({ isVisible: true, modalType: type });
  };

  return (
    <>
      <PillarInfoHeader>
        {columns.map((column, index) => (
          <div
            key={index}
            className={clsx(styles.columnStyle, styles.head, column.style)}
          >
            <span>{column.text}</span>
          </div>
        ))}
      </PillarInfoHeader>

      <PublishModal
        isVisible={publishModal.isVisible}
        modalType={publishModal.modalType}
        onClose={handleCloseModal}
        patient={patient}
        carePlanDetail={selectedTemplateData || null}
      />

      <div>
        {isLoading || isFetching ? (
          <Spinner />
        ) : pillarTemplateData?.contents?.length > 0 ? (
          pillarTemplateData?.contents?.map((item: any, index: number) => {
            const lastSavedTimeStamp = formatLastUpdatedDate(item?.updatedAt);
            const lastPublishedTimeStamp = item?.publishedAt
              ? formatLastUpdatedDate(item?.publishedAt)
              : "";
            const providerInfo = getProviderInfoById(item.updatedBy);

            return (
              <PillarInfoContainer>
                <div className={clsx(styles.rowStyle, styles.column1)}>
                  <span>{item?.patientGroupTypeName}</span>
                </div>
                <div className={clsx(styles.rowStyle, styles.column2)}>
                  <span>{lastSavedTimeStamp}</span>
                </div>
                <div className={clsx(styles.rowStyle, styles.column3)}>
                  {item?.updatedBy &&
                    (providerInfo ? (
                      <div className={styles.avatarDetail}>
                        {providerInfo?.photoUrl ? (
                          <img
                            src={providerInfo.photoUrl}
                            alt={`${providerInfo.name}'s avatar`}
                          />
                        ) : (
                          <div
                            className={styles.avatarPlaceholder}
                            aria-label="No avatar available"
                          >
                            {providerInfo?.name
                              .split(" ")
                              .map((n) => n[0])
                              .join("")
                              .toUpperCase()}
                          </div>
                        )}
                        <span>{providerInfo.name}</span>
                      </div>
                    ) : null)}
                </div>
                <div className={clsx(styles.rowStyle, styles.column4)}>
                  {item?.status !== undefined && (
                    <CarePlanStatus status={item?.status} />
                  )}
                </div>
                <div className={clsx(styles.rowStyle, styles.column5)}>
                  <span>{lastPublishedTimeStamp}</span>
                </div>
                <div
                  className={clsx(
                    styles.rowStyle,
                    styles.column6,
                    styles.right
                  )}
                >
                  <DotsThree
                    size={28}
                    className={styles.threeDots}
                    onClick={() => handleDotsClick(item?.careplanId)}
                  />
                  {openMenuId === item?.careplanId && (
                    <div
                      ref={(el) => (menuRefs.current[item?.careplanId] = el)}
                      className={styles.dropdownContainer}
                    >
                      <DropdownMenu>
                        {/* Edit option is always shown */}
                        <div
                          onClick={() => {
                            /* Handle Edit */
                            setOpenMenuId(null);
                            setSelectedTemplateData(item);
                            if (
                              typeof pillarDetail.onEditClick === "function"
                            ) {
                              pillarDetail.onEditClick();
                            }
                          }}
                        >
                          Edit
                        </div>

                        {/* Show Publish or Unpublish based on item.status */}
                        {item?.status === "changed" ||
                        item?.status === "draft" ? (
                          <div
                            onClick={() => {
                              /* Handle Publish */
                              setOpenMenuId(null);
                              setSelectedTemplateData(item);
                              handleOpenModal(PublishModalTypes.Published);
                            }}
                          >
                            Publish
                          </div>
                        ) : null}

                        {item?.status === "published" ? (
                          <div
                            onClick={() => {
                              /* Handle Unpublish */
                              setOpenMenuId(null);
                              setSelectedTemplateData(item);
                              handleOpenModal(PublishModalTypes.Unpublish);
                            }}
                          >
                            Unpublish
                          </div>
                        ) : null}
                      </DropdownMenu>
                    </div>
                  )}
                </div>
              </PillarInfoContainer>
            );
          })
        ) : (
          <NoCarePlan>
            <div className={styles.iconCircle}>
              <Article size={30} />
            </div>
            <strong>No Care Plan</strong>
            <span>You haven’t added a Care Plan to this pillar</span>
          </NoCarePlan>
        )}
      </div>
      {!isLoading && !isFetching && pillarTemplateData?.contents?.length > 0 ? (
        <nav className={styles.paginationContainer}>
          <Button
            disabled={currentPage === 0}
            label="<<"
            onClick={() => changePage(0)}
          />
          <Button
            disabled={currentPage === 0}
            label="<"
            onClick={() => changePage(currentPage - 1)}
          />
          <Button
            disabled={currentPage === pageCount - 1}
            label=">"
            onClick={() => changePage(currentPage + 1)}
          />
          <Button
            disabled={currentPage === pageCount - 1}
            label=">>"
            onClick={() => changePage(pageCount - 1)}
          />
          <div>
            <h3>
              Page {currentPage + 1} of {pageCount}
            </h3>
          </div>
        </nav>
      ) : null}
    </>
  );
};
