import { useEffect, useRef } from "react";

export const useUserActivityTracker = ({
  onActive,
  onInactive,
  inactiveTimeout = 30 * 60 * 1000, // Default timeout of 30 minutes (in milliseconds)
}: {
  onActive?: () => void;
  onInactive?: () => void;
  inactiveTimeout?: number;
}) => {
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const lastActivityRef = useRef<number>(Date.now());

  // Function to reset the inactivity timeout
  const resetInactivityTimeout = () => {
    lastActivityRef.current = Date.now();
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      const currentTime = Date.now();
      if (currentTime - lastActivityRef.current >= inactiveTimeout) {
        onInactive?.();
      }
    }, inactiveTimeout);
  };

  useEffect(() => {
    // Event listeners for tracking user activity
    const activityHandler = () => {
      if (Date.now() - lastActivityRef.current >= inactiveTimeout) {
        onActive?.();
      }
      resetInactivityTimeout();
    };

    const focusHandler = () => {
      if (document.visibilityState === "visible") {
        activityHandler();
      }
    };

    // Attach event listeners
    window.addEventListener("mousemove", activityHandler);
    window.addEventListener("keydown", activityHandler);
    window.addEventListener("mousedown", activityHandler);
    window.addEventListener("touchstart", activityHandler);
    window.addEventListener("focus", focusHandler);
    document.addEventListener("visibilitychange", focusHandler);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("mousemove", activityHandler);
      window.removeEventListener("keydown", activityHandler);
      window.removeEventListener("mousedown", activityHandler);
      window.removeEventListener("touchstart", activityHandler);
      window.removeEventListener("focus", focusHandler);
      document.removeEventListener("visibilitychange", focusHandler);

      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [onActive, onInactive, inactiveTimeout]);
};
