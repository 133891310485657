import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Amplify } from "aws-amplify";
import {
  fetchAuthSession,
  JWT,
  signInWithRedirect,
  signOut as _signOut,
} from "aws-amplify/auth";
import { sessionStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import axios from "axios";
import cognitoConfig from "../config/cognito.config";
import { Modal } from "../components/modal/Modal";
import { LogoutPromptForm } from "../containers/logout/LogoutPromptForm";
import { useUserActivityTracker } from "./useUserActivityTracker";

const API_URL = process.env.REACT_APP_API_URL;

Amplify.configure({
  Auth: cognitoConfig,
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

axios.defaults.baseURL = API_URL;

export interface AuthContextType {
  loading: boolean;
  token?: JWT | null;
  user?: CognitoUser | null;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  authError?: string | null;
}

export interface BaseUser {
  sub: string;
  email: string;
  [key: string]: any;
}

export interface CognitoUser extends BaseUser {
  "custom:okta_sub"?: string;
}

export const AuthContext = createContext<AuthContextType>({
  loading: false,
  signIn: async () => {},
  signOut: async () => {},
});

// Helper function to check if the user is in a video visit
const checkIfInVideoChat = (): boolean => {
  const video = localStorage.getItem("video");
  const videodata = video ? JSON.parse(video) : null;
  return videodata && videodata.videoJoined;
};

export const AuthContextProvider = ({
  children,
  authError,
}: {
  children: React.ReactNode;
  authError?: string | null;
}) => {
  const history = useHistory();
  const [token, setToken] = useState<JWT | null>(null);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [isUserActive, setIsUserActive] = useState<boolean>(true);
  const modalTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useUserActivityTracker({
    onActive: () => {
      setIsUserActive(true);
    },
    onInactive: () => {
      setIsUserActive(false);
      if (!checkIfInVideoChat()) {
        setShowLogoutModal(true);
      }
    },
    inactiveTimeout: 30 * 60 * 1000,
  });

  useEffect(() => {
    const checkAuth = async () => {
      setLoading(true);
      try {
        const session = await fetchAuthSession();
        if (!session.tokens) {
          history.push("/login");
        } else {
          setToken(session.tokens?.idToken || null);
          setUser((session.tokens?.idToken?.payload as CognitoUser) || null);
        }
      } catch (error) {
        console.error("Error fetching Cognito session:", error);
        await signIn();
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  const signIn = async () => {
    await signInWithRedirect({ provider: { custom: "EntraOIDC" } });
  };

  const signOut = async () => {
    await _signOut({ global: true });

    setToken(null);
    setUser(null);
    history.push("/");
  };

  const refreshToken = async () => {
    try {
      const session = await fetchAuthSession({ forceRefresh: true });
      const newToken = session.tokens?.idToken;
      if (newToken) {
        setToken(newToken);
        return newToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
    return null;
  };

  const handleLogoutNow = () => {
    setShowLogoutModal(false);
    clearTimeout(modalTimeoutRef.current!); // Clear timeout if user takes action
    signOut();
  };

  const handleStayConnected = async () => {
    setShowLogoutModal(false);
    clearTimeout(modalTimeoutRef.current!); // Clear timeout if user takes action
    const refreshedToken = await refreshToken();
    if (refreshedToken) {
      setToken(refreshedToken);
    }
  };

  // Periodically check token expiry and show logout modal if close to expiration
  useEffect(() => {
    if (token) {
      const checkTokenExpiry = async () => {
        const currentTime = Date.now();
        const tokenExpiryTime = (token.payload?.exp || 0) * 1000;
        const timeRemaining = tokenExpiryTime - currentTime;

        if (timeRemaining > 0 && timeRemaining <= 5 * 60 * 1000) {
          // 5 minutes before token expiry
          if (isUserActive) {
            // If the user is active, refresh the token
            const refreshedToken = await refreshToken();
            if (!refreshedToken && !checkIfInVideoChat()) {
              setShowLogoutModal(true); // Show modal if refresh fails
            }
          } else if (!checkIfInVideoChat()) {
            // Show modal if the user is inactive and not in video chat
            setShowLogoutModal(true);
          }
        }
      };

      const intervalId = setInterval(checkTokenExpiry, 60000); // Check every 1 minute
      return () => clearInterval(intervalId);
    }
  }, [token, isUserActive]);

  // Handle automatic logout if modal is displayed for 2 minutes without interaction
  useEffect(() => {
    if (showLogoutModal) {
      modalTimeoutRef.current = setTimeout(() => {
        signOut();
      }, 120000); // 2 minutes
    } else {
      clearTimeout(modalTimeoutRef.current!); // Clear timeout if modal is closed
    }
    return () => clearTimeout(modalTimeoutRef.current!); // Cleanup on component unmount
  }, [showLogoutModal]);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token.toString()}`;
    }
  }, [token]);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const newToken = await refreshToken();
          if (newToken) {
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${newToken.toString()}`;
            return axios(originalRequest);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{ loading, token, user, signIn, signOut, authError }}
    >
      {children}
      <Modal
        dismissable={false}
        visible={showLogoutModal}
        onCloseModal={() => {
          setShowLogoutModal(false);
        }}
        title="Would you like to remain connected?"
      >
        <LogoutPromptForm
          onLogoutNow={handleLogoutNow}
          onStayConnected={handleStayConnected}
        />
      </Modal>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
